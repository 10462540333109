import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthFlows, AuthSteps } from "../../../constants/authSteps";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import useFetchMyStudiosQuery from "../../../hooks/authHooks/useFetchMyStudiosQuery";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import useActiveStudiosMutation from "../../../hooks/userHooks/useActiveStudiosMutation";
import { useUsernameCheck } from "../../../hooks/usernameCheck";
import {
  createOrDeleteStudioProfile,
  updateStudio,
  updateStudioProfileParams,
} from "../../../store/actions/studio";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getHomepageRoute,
  getStudioScreenRoute,
} from "../../../store/utils/routeGetters";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import { LocationInput } from "../LocationInput/LocationInput";
import { FieldInput } from "../SignInForm/SignInForm.styles";
import { currentFlowAtom } from "../../../atoms/location/currentFlowAtom";
import {
  CloseButton,
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import FooterContent from "./FooterContent";
import { currentSignUpStepAtom } from "../../../atoms/location/currentSignUpStepAtom";
import { authModalOpenAtom } from "../../../atoms/location/authModalOpenAtom";

const StudioLocation = () => {
  const setAuthStep = useSetAtom(currentSignUpStepAtom);
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountInfo.user);
  const [studioUsername, setStudioUsername] = useState("");
  const [showUsernameError, setShowUsernameError] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<updateStudioProfileParams>({
    studio_id: undefined,
    location: undefined,
  });
  const [creatingStudioProfile, setCreatingStudioProfile] =
    useState<boolean>(false);
  const authNavigation = useAuthNavigationSteps();
  const { nextStep } = authNavigation();
  const [hasSpace, hasSpecialCharacter] = useUsernameCheck(studioUsername);
  const { data: existingStudios } = useFetchMyStudiosQuery();
  const [editingStudioProfile, setEditingStudioProfile] = useState(false);
  const currentFlow = useAtomValue(currentFlowAtom);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const { isDesktop } = useMediaQueryBreakpoint();
  const homepageRoute = getHomepageRoute();
  const { mutate: mutateActiveStudios } = useActiveStudiosMutation();

  useEffect(() => {
    if (existingStudios && existingStudios.length > 0) {
      setEditingStudioProfile(true);
      setStudioUsername(existingStudios[0].username);
      setProfileData({
        studio_id: existingStudios[0].id,
      } as updateStudioProfileParams);
      mutateActiveStudios();
    }
  }, [existingStudios]);

  useEffect(() => {
    const shouldShowError =
      studioUsername.length > 0 && (hasSpace || hasSpecialCharacter);
    setShowUsernameError(shouldShowError);
  }, [studioUsername, hasSpace, hasSpecialCharacter]);

  const handleCreateNewStudioProfile = useCallback(() => {
    if (creatingStudioProfile) return;
    if (showUsernameError) return;
    setCreatingStudioProfile(true);
    if (editingStudioProfile) {
      if (existingStudios) {
        dispatch(
          updateStudio({
            ...profileData,
            ...(existingStudios[0].username !== studioUsername && {
              username: studioUsername,
            }),
          }),
        )
          .unwrap()
          .then((response) => {
            if (currentFlow === AuthFlows.ADD_STUDIO) {
              setAuthModalOpen(false);
              history.push(getStudioScreenRoute(response.username));
            } else {
              nextStep();
            }
          })
          .catch(() => {
            toast.error("Failed to update studio");
          })
          .finally(() => {
            setCreatingStudioProfile(false);
          });
      }
      return;
    }
    dispatch(
      createOrDeleteStudioProfile({
        studio_username: studioUsername,
        deleted: false,
      }),
    )
      .unwrap()
      .then((response) => {
        emitAnalyticsTrackingEvent(
          "auth_studio_location_click_create_studio",
          { studio: response.id, studio_username: response.username },
          user?.id,
        );
        const updatedProfileData = {
          ...profileData,
          studio_id: response.id,
        };
        setProfileData(updatedProfileData);
        dispatch(updateStudio(updatedProfileData))
          .unwrap()
          .then((response) => {
            mutateActiveStudios();
            if (currentFlow === AuthFlows.ADD_STUDIO) {
              setAuthModalOpen(false);
              history.push(getStudioScreenRoute(response.username));
            } else {
              nextStep();
            }
          })
          .catch(() => {
            toast.error("Failed to set location.");
          });
      })
      .catch(() => {
        toast.error("Failed to set username.");
      })
      .finally(() => {
        setCreatingStudioProfile(false);
      });
  }, [
    studioUsername,
    user,
    dispatch,
    profileData,
    creatingStudioProfile,
    editingStudioProfile,
  ]);

  if (!user) {
    setAuthStep(AuthSteps.START_LOGIN);
    return null;
  }

  return (
    <FormContainer>
      {currentFlow === AuthFlows.ADD_STUDIO && (
        <CloseButton>
          <FontAwesomeIcon
            icon={faClose}
            size="lg"
            color={theme.palette.text.primary}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "auth_add_studio_profile_clicked_close",
                {},
              );
              setAuthModalOpen(false);
              // this is a temporary solution. Auth needs to be modal both on desktop and mobile
              if (!isDesktop) history.replace(homepageRoute);
            }}
          />
        </CloseButton>
      )}
      {currentFlow !== AuthFlows.ADD_STUDIO && <AuthNavigation />}
      <MainContentContainer>
        <Text variant={TextStyleVariant.H6}>
          Get your studio profile started
        </Text>
        <Box sx={{ width: "100%" }}>
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Studio Username</Text>
          <Box marginTop="10px">
            <FieldInput
              sx={{ width: "100%" }}
              value={studioUsername}
              onChange={(e) => {
                setStudioUsername(e.target.value);
              }}
              size="small"
              disabled={creatingStudioProfile}
              error={showUsernameError}
              helperText={
                showUsernameError ? "May contain only letters and numbers" : ""
              }
            />
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Studio address</Text>
          <Box marginTop="10px">
            <LocationInput
              defaultValue=""
              onPlaceSelected={(placeResult) => {
                setProfileData((currentData) => ({
                  ...currentData,
                  location: placeResult,
                }));
              }}
              showBorder
            />
          </Box>
        </Box>
        <Button
          style={{ width: "100%" }}
          onClick={handleCreateNewStudioProfile}
          loading={creatingStudioProfile}
          disabled={
            !studioUsername.length ||
            !profileData.location ||
            creatingStudioProfile
          }
        >
          {editingStudioProfile ? "Update my studio" : "Create my studio"}
        </Button>
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
export default StudioLocation;
