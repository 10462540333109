import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { faCheck, faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { KeyboardEventHandler, useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { assertEngineEarsErrorType } from "../../../api/helpers";
import { AuthFlows } from "../../../constants/authSteps";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { useCheckUserLoginFlow } from "../../../hooks/authHooks/useCheckUserLoginFlow";
import useGoogleAuth from "../../../hooks/authHooks/useGoogleAuth";
import useLoginMutation from "../../../hooks/authHooks/useLoginMutation";
import useSignUpMutation from "../../../hooks/authHooks/useSignUpMutation";
import { useUsernameCheck } from "../../../hooks/usernameCheck";
import { useSetPageTitle } from "../../../hooks/useSetPageTitle";
import { RootState } from "../../../store";
import { updateProfile } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { loginRequest } from "../../../utils/umgAuthConfig";
import GoogleLogoIcon from "../../assets/google-logo-icon.svg";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text, TEXT_WEIGHT } from "../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../core-ui/components/Text/TextUtils";
import {
  FieldContainer,
  FieldInput,
  FormContainer,
  UsernameChecksContainer,
  UsernameRequirement,
} from "./SignInForm.styles";
import { usePasswordCheck } from "../../../hooks/userPasswordCheck";
import { SignInFormPasswordField } from "./SignInFormPasswordField";
import { useDebouncedUsernameCheck } from "./useDebouncedUsernameCheck";
import useAuthHandler from "../../../hooks/authHooks/useAuthHandler";
import { authRedirectPath } from "../../../atoms/auth/authRedirectPath";
import { authModalOpenAtom } from "../../../atoms/location/authModalOpenAtom";
import { currentFlowAtom } from "../../../atoms/location/currentFlowAtom";

export interface signUpErrorProps {
  code: number;
  username: string;
  email: string;
  password: string;
}

export interface SignInFormProps {
  primary?: boolean;
  register: boolean;
  registerUsername?: boolean;
  showWarning?: boolean;
  defaultValueEmail?: string;
  defaultValueUsername?: string;
  skipFlow?: boolean;
  hideGoogle?: boolean;
  // Optional prop that will handle swapping between login and signup.
  includeSignInOrRegisterSelect?: boolean;
  phoneLoginAddEmail?: boolean;
}

// Not sure if this is necessary - seems to indicate loading/disabled state
// allows `useAuthenticateUMG` hook to set `isUmgPortalOpenAtom`
export const isUmgPortalOpenAtom = atom(false);

export const SignInForm = ({
  register,
  showWarning = true,
  registerUsername = false,
  defaultValueEmail,
  defaultValueUsername,
  skipFlow = false,
  hideGoogle = false,
  includeSignInOrRegisterSelect = false,
  phoneLoginAddEmail = false,
  ...props
}: SignInFormProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const authNavigation = useAuthNavigationSteps();
  const { checkUserAndSetFlow } = useCheckUserLoginFlow();
  const { nextStep } = authNavigation();
  const [flow, setFlow] = useAtom(currentFlowAtom);
  const isPhoneSignUp =
    flow === AuthFlows.PHONE_SIGNUP || flow === AuthFlows.ADD_PASSWORD;
  const { user, isUpdatingProfile } = useAppSelector(
    (state: RootState) => state.accountInfo,
  );
  const [showSignUp, setShowSignUp] = useState<boolean>(Boolean(register));
  const [username, setUsername] = useState<string>(
    showSignUp ? user?.username ?? defaultValueUsername ?? "" : "",
  );

  const { usernameExists, usernameBelongsToPasswordlessAccount } =
    useDebouncedUsernameCheck(username);
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>(defaultValueEmail ?? "");

  const isValidEmailUtil = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const isValidEmail = useMemo(() => {
    return isValidEmailUtil(email);
  }, [email]);

  const enteredUMGEmail = useMemo(() => {
    return (
      email.toLowerCase().includes("@umusic.com") ||
      email.toLowerCase().includes("@umgconsult.com")
    );
  }, [email]);

  const [loading, setLoading] = useState<boolean>(false);
  const [signUpError, setSignUpError] = useState<signUpErrorProps>();
  const { instance } = useMsal();
  const [isUmgPortalOpen, setIsUmgPortalOpen] = useAtom(isUmgPortalOpenAtom);
  const isUmgAuthenticated = useIsAuthenticated();
  const localUTMParams = useAppSelector(
    (state) => state.accountInfo.localUTMParams,
  );
  const [hasSpace, hasSpecialCharacter] = useUsernameCheck(username);
  const { handleGoogleClick, isGoogleLoginOpen } = useGoogleAuth();
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const { mutateAsync: loginAsync, isPending: isLoginPending } =
    useLoginMutation();
  const { mutateAsync: signUpAsync, isPending: isSignUpPending } =
    useSignUpMutation();
  const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
    usePasswordCheck(password, 8);
  const isValidPassword =
    validLength && hasNumber && upperCase && lowerCase && specialChar;
  const { handleRedirect } = useAuthHandler();
  useSetPageTitle(showSignUp ? "Sign Up" : "Sign In");

  const handleSubmit = useCallback(
    async (e: { preventDefault: () => void }) => {
      e.preventDefault();
      setLoading(true);
      if (showSignUp) {
        if (isValidEmailUtil(username)) {
          setSignUpError({
            code: 400,
            username: "Username cannot be an email address",
            email: "",
            password: "",
          });
          setLoading(false);
          return;
        }
        emitAnalyticsTrackingEvent(
          "register",
          {
            skipFlow: `${skipFlow}`,
            username: `${username}`,
            email: `${email}`,
            ...localUTMParams,
          },
          user?.id,
        );
        signUpAsync({
          username,
          password,
          email,
        })
          .then((result) => {
            if (result && !assertEngineEarsErrorType(result)) {
              if (skipFlow) {
                setAuthModalOpen(false);
              } else if (
                result.user.is_valid_aandr_email === true &&
                result.user.is_umg_whitelisted_email === false
              ) {
                setFlow(AuthFlows.AANDR_NON_UMG);
              } else {
                setFlow(AuthFlows.STANDARD_SIGNUP);
              }
              handleRedirect();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        loginAsync({
          username: username.length > 0 ? username : email,
          password,
        })
          .then((result) => {
            if (result && !assertEngineEarsErrorType(result)) {
              emitAnalyticsTrackingEvent(
                "login",
                {
                  skipFlow: `${skipFlow}`,
                  user_id: `${result.user.id}`,
                  username: `${username}`,
                  ...localUTMParams,
                },
                result.user.id,
              );
              checkUserAndSetFlow(result.user, skipFlow);
              handleRedirect();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [
      showSignUp,
      username,
      skipFlow,
      email,
      localUTMParams,
      user?.id,
      signUpAsync,
      password,
      setAuthModalOpen,
      setFlow,
      loginAsync,
      checkUserAndSetFlow,
    ],
  );

  const redirectPath = useAtomValue(authRedirectPath);

  const handleUmgLogin = () => {
    emitAnalyticsTrackingEvent("auth_click_on_umg_login", {}, user?.id);
    setIsUmgPortalOpen(true);

    instance
      .loginRedirect({
        ...loginRequest,
        redirectStartPage: redirectPath
          ? `${window.location.origin}${redirectPath}`
          : undefined,
      })
      .catch((e: any) => {
        toast.error(
          "Something went wrong with the login request. Please contact support.",
        );
        setIsUmgPortalOpen(false);
      });
  };

  const handleUpdateUsername = useCallback(() => {
    if (!username || username.length < 1) return;
    const timezoneOffset = new Date().getTimezoneOffset();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(
      updateProfile({
        username: username,
        timezone_shift_minutes: timezoneOffset,
        timezone,
      }),
    )
      .unwrap()
      .then(() => {
        emitAnalyticsTrackingEvent(
          "auth_click_on_create_account",
          {
            username: `${username}`,
          },
          user?.id,
        );
        nextStep();
      })
      .catch((err) => {
        setSignUpError(err.errors);
      });
  }, [username, user, dispatch]);

  const handleUpdateEmailAndUsername = useCallback(() => {
    if (!email || email.length < 1 || !username || username.length < 1) return;
    const timezoneOffset = new Date().getTimezoneOffset();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(
      updateProfile({
        username: username,
        email: isValidEmail ? email : undefined,
        timezone_shift_minutes: timezoneOffset,
        timezone,
      }),
    )
      .unwrap()
      .then(() => {
        emitAnalyticsTrackingEvent(
          "auth_click_on_create_account",
          {
            username: `${username}`,
          },
          user?.id,
        );
        nextStep();
      })
      .catch((err) => {
        setSignUpError(err.errors);
      });
  }, [email, user, dispatch, isValidEmail]);

  const usernameRequirement = (label: string, isPassing: boolean) => (
    <UsernameRequirement>
      <FontAwesomeIcon
        icon={isPassing ? faClose : faCheck}
        color={
          isPassing ? theme.colorPalette.Red600 : theme.colorPalette.Green600
        }
      />
      <Text
        variant={TextStyleVariant.P3}
        color={isPassing ? TextColor.FAILED : TextColor.SUCCESS}
      >
        {label}
      </Text>
    </UsernameRequirement>
  );

  const handleSubmitOnEnterKey: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key !== "Enter") return;
    if (showSignUp && registerUsername && username.length > 0) {
      if (isPhoneSignUp) {
        handleUpdateEmailAndUsername();
      } else {
        handleUpdateUsername();
      }
    } else if (enteredUMGEmail) {
      handleUmgLogin();
    } else if (isValidPassword) {
      void handleSubmit(e);
    }
  };
  return (
    <FormContainer>
      {
        // Username field (if logging in or signing up username step)
        ((showSignUp && registerUsername) || !showSignUp) && (
          <>
            <FieldContainer>
              <Text weight={TEXT_WEIGHT.SEMI_BOLD}>
                {showSignUp ? "Choose a username" : "Username or Email"}
              </Text>
              <FieldInput
                defaultValue={email && !showSignUp ? email : username}
                disabled={loading || isUpdatingProfile}
                fullWidth={true}
                size={"small"}
                onChange={(e) => {
                  const value = e.target.value;
                  setSignUpError(undefined);
                  setUsername(value);
                  setEmail(value);
                }}
                onKeyDown={handleSubmitOnEnterKey}
                error={Boolean(signUpError?.username)}
                helperText={signUpError?.username}
              />
              {username && showSignUp && registerUsername && (
                <UsernameChecksContainer>
                  {usernameRequirement("No spaces allowed", hasSpace)}
                  {usernameRequirement(
                    "No special characters (dashes and underscores allowed)",
                    hasSpecialCharacter,
                  )}
                </UsernameChecksContainer>
              )}
              {register && registerUsername && phoneLoginAddEmail && (
                <>
                  <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Add your email</Text>
                  <FieldInput
                    disabled={loading || isUpdatingProfile}
                    fullWidth={true}
                    size={"small"}
                    onChange={(e) => {
                      const email = e.target.value;
                      setEmail(email);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (isValidEmail && email.length > 0) {
                          handleUpdateEmailAndUsername();
                        }
                      }
                    }}
                    error={Boolean(signUpError?.username)}
                    helperText={signUpError?.username}
                  />
                </>
              )}
            </FieldContainer>
            {showSignUp && registerUsername && (
              <Button
                fullWidth={true}
                loading={isUpdatingProfile}
                variant={ButtonVariant.PRIMARY}
                disabled={
                  !username ||
                  hasSpace ||
                  hasSpecialCharacter ||
                  isUpdatingProfile
                }
                onClick={
                  isPhoneSignUp
                    ? handleUpdateEmailAndUsername
                    : handleUpdateUsername
                }
              >
                Create your account
              </Button>
            )}
          </>
        )
      }
      {
        // Email field (if signing up)
        showSignUp && !registerUsername && (
          <FieldContainer>
            <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Email</Text>
            <FieldInput
              defaultValue={defaultValueEmail}
              fullWidth={true}
              size={"small"}
              onChange={(e) => {
                const email = e.target.value;
                setEmail(email);
              }}
              onKeyDown={handleSubmitOnEnterKey}
              InputProps={{
                endAdornment: isValidEmail ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : null,
              }}
              error={Boolean(signUpError?.email)}
              helperText={signUpError?.email}
            />
          </FieldContainer>
        )
      }
      {
        // Password field (if signing up or logging in)
        !enteredUMGEmail && !registerUsername && (
          <SignInFormPasswordField
            email={email}
            emailIsValid={isValidEmail && !enteredUMGEmail}
            registering={showSignUp}
            showWarning={showWarning}
            newPassword={showSignUp}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            usernameExists={Boolean(usernameExists)}
            usernameBelongsToPasswordlessAccount={Boolean(
              usernameBelongsToPasswordlessAccount,
            )}
            setShowSignUp={setShowSignUp}
            error={Boolean(signUpError?.password) && !password.length}
            helperText={!password.length ? signUpError?.password ?? "" : ""}
            loading={loading}
            handleSubmit={handleSubmit}
            isSubmitDisabled={
              showSignUp
                ? !email || !isValidEmail || !password.length
                : !username.length || !password.length
            }
            showSignUp={showSignUp}
            includeSignInOrRegisterSelect={
              includeSignInOrRegisterSelect && !enteredUMGEmail
            }
          />
        )
      }
      {
        // Google SSO
        !showSignUp && !hideGoogle && !enteredUMGEmail && (
          <Button
            fullWidth={true}
            variant={ButtonVariant.OUTLINED}
            style={{ gap: "8px", minWidth: "162px", maxHeight: "44px" }}
            onClick={handleGoogleClick}
            loading={isGoogleLoginOpen}
            disabled={isGoogleLoginOpen}
          >
            <img
              alt={"google logo icon"}
              src={GoogleLogoIcon}
              width={18}
              height={18}
            />
            Continue with Google
          </Button>
        )
      }
      {
        // UMG SSO
        enteredUMGEmail && (
          <Button
            className={"mt-3"}
            variant={ButtonVariant.PRIMARY}
            fullWidth={true}
            loading={loading || isUmgAuthenticated || isUmgPortalOpen}
            disabled={loading || isUmgAuthenticated || isUmgPortalOpen}
            onClick={handleUmgLogin}
          >
            Sign {showSignUp ? "up" : "in"} with UMG SSO
          </Button>
        )
      }
    </FormContainer>
  );
};
