import { ComponentType, FC, lazy } from "react";
import { ENV } from "../constants/generated";
import { SCREENS } from "./screens";
import BeatMatrixScreen from "../stories/screens/BeatMatrixScreen/BeatMatrixScreen";

export interface RouteConfig {
  path: string | string[];
  component: ComponentType | FC;
  exact?: boolean;
}

export enum EXTERNAL_LINKS {
  BLOG = "https://blog.engineears.com/",
  FAQ = "https://support.engineears.com/",
  MUSO = "https://muso.ai/",
  SUPPORT = "https://support.engineears.com/",
  ARTIST_CALENDLY = "https://calendly.com/engineears-team/1-1-w-artist-relations",
}

/**
 * Persona Pages are made via Webflow and are not part of the routing system
 */
export enum PERSONA_PAGES {
  ARTIST = "https://community.engineears.com/for-artists",
  ENGINEER = "https://community.engineears.com/for-engineers",
  STUDIO = "https://community.engineears.com/for-studios",
  LABEL = "https://community.engineears.com/for-labels",
}

// Be careful not to let these get out of sync with the routes below.
// Having an enum in an enum creates a compilation error
export enum ROUTE_PREFIXES {
  CUSTOMER_PORTAL = "/customer-portal",
  BOOKING = "/booking",
  BOOKINGS = "/bookings",
  FEATURED_TRACK = "/featured-track",
  LISTEN = "/listen",
  MUSO = "/muso",
  PROJECT = "/project",
  PROJECTS = "/projects",
  PROJECT_OVERVIEW = "/project-overview",
  UPLOAD = "/upload",
  SESSION = "/session",
  STUDIO = "/studio",
}

// This is used in the nav bars to highlight the icon
export const PROJECT_SCREEN_PATHS = [
  SCREENS.PROJECTS_NO_TAB,
  ROUTE_PREFIXES.BOOKINGS,
];

export const SOUND_OF_TOMORROW_LANDING =
  "https://community.engineears.com/soundoftomorrow";
export const DREAMVILLE_LANDING = "https://community.engineears.com/dreamville";
export const NAMM_LANDING =
  "https://engineears.com/community?path=community-events/post/engineears-namm-2025-6GPrwytJ0vv2Ke7";
export const MIXHER_LANDING = "https://community.engineears.com/mixher";

const routes: RouteConfig[] = [
  {
    path: SCREENS.MY_LIBRARY,
    component: lazy(() => import("../stories/screens/MyLibrary/MyLibrary")),
  },
  {
    path: SCREENS.RELEASE_CHECKOUT,
    component: lazy(
      () =>
        import(
          "../stories/screens/ReleasesCheckoutScreen/ReleasesCheckoutScreen"
        ),
    ),
  },
  {
    path: SCREENS.RELEASE_DETAIL,
    component: lazy(
      () =>
        import("../stories/screens/ReleaseDetailScreen/ReleaseDetailScreen"),
    ),
  },
  {
    path: [ROUTE_PREFIXES.BOOKINGS, SCREENS.BOOKINGS],
    component: lazy(
      () => import("../stories/screens/BookingsScreen/BookingsScreen"),
    ),
  },
  {
    path: SCREENS.THE_SOUND_OF_TOMORROW,
    component: () => {
      window.location.href = SOUND_OF_TOMORROW_LANDING;
      return null;
    },
  },
  {
    path: SCREENS.SOUND_OF_TOMORROW,
    component: () => {
      window.location.href = SOUND_OF_TOMORROW_LANDING;
      return null;
    },
  },
  {
    path: SCREENS.THE_SOUND_OF_TOMORROW,
    component: () => {
      window.location.href = SOUND_OF_TOMORROW_LANDING;
      return null;
    },
  },
  {
    path: SCREENS.SEEING_SOUNDS,
    component: () => {
      window.location.href = DREAMVILLE_LANDING;
      return null;
    },
  },
  {
    path: SCREENS.DREAMVILLE,
    component: () => {
      window.location.href = DREAMVILLE_LANDING;
      return null;
    },
  },
  {
    path: SCREENS.MIXHER,
    component: () => {
      window.location.href = MIXHER_LANDING;
      return null;
    },
  },

  // Site routes
  {
    path: SCREENS.PAGINATED_STUDIOS,
    component: lazy(
      () =>
        import(
          "../stories/screens/PaginatedVerifiedEntityScreens/PaginatedVerifiedStudioRoomsScreen"
        ),
    ),
  },
  {
    path: SCREENS.PAGINATED_STUDIOS_ALT,
    component: lazy(
      () =>
        import(
          "../stories/screens/PaginatedVerifiedEntityScreens/PaginatedVerifiedStudioRoomsScreen"
        ),
    ),
  },
  {
    path: SCREENS.PAGINATED_ENGINEERS,
    component: lazy(
      () =>
        import(
          "../stories/screens/PaginatedVerifiedEntityScreens/PaginatedVerifiedEntityScreen"
        ),
    ),
  },
  {
    path: SCREENS.DASHBOARD_FAVORITES,
    component: lazy(
      () => import("../stories/screens/FavoritesScreen/FavoritesScreen"),
    ),
  },
  {
    path: SCREENS.RECORDING_SESSION,
    component: lazy(
      () =>
        import(
          "../stories/screens/RecordingSessionScreen/RecordingSessionScreen"
        ),
    ),
  },
  {
    path: SCREENS.AANDR_SETUP_SCREEN,
    component: lazy(
      () => import("../stories/screens/AandRSetupScreen/AandRSetupScreen"),
    ),
  },
  {
    path: SCREENS.DOLBY_REGISTRATION_CHECKPOINT,
    component: lazy(
      () =>
        import(
          "../stories/screens/DolbyCourseRegistrationPage/DolbyCourseRegistrationPage"
        ),
    ),
  },
  {
    path: SCREENS.NAMM_REGISTRATION_CHECKPOINT,
    component: lazy(
      () =>
        import("../stories/screens/NammRegistrationPage/NammRegistrationPage"),
    ),
  },
  {
    path: SCREENS.NAMM,
    component: () => {
      window.location.href = NAMM_LANDING;
      return null;
    },
  },
  {
    path: SCREENS.USER_FEEDBACK_RATING,
    component: lazy(
      () =>
        import(
          "../stories/screens/UserSatisfactionRating/UserSatisfactionRating"
        ),
    ),
  },
  {
    path: SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN,
    component: lazy(
      () =>
        import(
          "../stories/screens/LoggedOutProjectOverview/ScheduledProjectOverviewScreen"
        ),
    ),
  },
  {
    path: SCREENS.MOBILE_CHECKOUT,
    component: lazy(
      () =>
        import("../stories/screens/MobileCheckoutScreen/MobileCheckoutScreen"),
    ),
  },
  {
    path: SCREENS.STUDIO_ROOM,
    component: lazy(
      () => import("../stories/screens/StudioScreen/StudioRoomScreen"),
    ),
  },
  {
    path: SCREENS.STUDIO_SCREEN,
    component: lazy(
      () => import("../stories/screens/StudioScreen/StudioScreen"),
    ),
  },
  {
    path: SCREENS.LOGGED_OUT_REVIEW,
    component: lazy(
      () =>
        import(
          "../stories/screens/LoggedOutReviewScreen/LoggedOutReviewScreen"
        ),
    ),
  },
  {
    path: SCREENS.UPLOAD_SCREEN,
    component: lazy(
      () =>
        import(
          "../stories/screens/LoggedOutUploadScreen/LoggedOutUploadScreen"
        ),
    ),
  },
  {
    path: SCREENS.LOGGED_OUT_SESSION_DETAILS,
    component: lazy(
      () =>
        import(
          "../stories/screens/LoggedOutRecordingSessionDetailsScreen/LoggedOutRecordingSessionDetailsScreen"
        ),
    ),
  },
  {
    path: SCREENS.PASSWORD_RESET,
    component: lazy(
      () =>
        import("../stories/screens/ResetPasswordScreen/ResetPasswordScreen"),
    ),
  },
  {
    path: SCREENS.SIGN_IN_SCREEN,
    component: lazy(() => import("../stories/screens/HomeScreen/HomeScreen")),
  },
  {
    path: SCREENS.SIGN_UP_SCREEN,
    component: lazy(() => import("../stories/screens/HomeScreen/HomeScreen")),
  },
  {
    path: SCREENS.SIGNED_OUT_HOME_SCREEN,
    component: lazy(() => import("../stories/screens/HomeScreen/HomeScreen")),
  },
  {
    path: SCREENS.LEGACY_FOR_ARTISTS,
    component: lazy(() => import("../stories/screens/HomeScreen/HomeScreen")),
  },
  {
    path: SCREENS.LEGACY_FOR_ENGINEERS,
    component: lazy(() => import("../stories/screens/HomeScreen/HomeScreen")),
  },
  {
    path: SCREENS.HOME_SCREEN,
    component: lazy(
      () => import("../stories/screens/Dashboard/ModularDashboard"),
    ),
  },
  {
    path: SCREENS.DASHBOARD,
    component: lazy(
      () => import("../stories/screens/Dashboard/ModularDashboard"),
    ),
  },
  {
    path: SCREENS.CUSTOMER_PORTAL,
    component: lazy(
      () =>
        import(
          "../stories/screens/CustomerPortalHomeScreen/CustomerPortalIndex"
        ),
    ),
  },
  {
    path: SCREENS.KLARNA_PAYMENT_REDIRECT,
    component: lazy(
      () =>
        import(
          "../stories/screens/StripePaymentRedirectScreen/KlarnaPaymentRedirectScreen"
        ),
    ),
  },
  {
    path: SCREENS.CASH_APP_REDIRECT,
    component: lazy(
      () =>
        import(
          "../stories/screens/StripePaymentRedirectScreen/CashAppPaymentRedirectScreen"
        ),
    ),
  },
  {
    path: SCREENS.AFFIRM_PAYMENT_REDIRECT,
    component: lazy(
      () =>
        import(
          "../stories/screens/StripePaymentRedirectScreen/AffirmPaymentRedirectScreen"
        ),
    ),
  },
  {
    path: SCREENS.MUSO_AUTH_REDIRECT_SCREEN,
    component: lazy(
      () =>
        import(
          "../stories/screens/MusoAuthRedirectScreen/MusoAuthRedirectScreen"
        ),
    ),
  },
  {
    path: SCREENS.MUSO_SELECTION_SCREEN,
    component: lazy(
      () =>
        import("../stories/screens/MusoSelectionScreen/MusoSelectionScreen"),
    ),
  },
  {
    path: SCREENS.VERIFY_EMAIL,
    component: lazy(
      () => import("../stories/screens/VerifyEmailScreen/VerifyEmailScreen"),
    ),
  },
  {
    path: SCREENS.SETUP_STRIPE,
    component: lazy(
      () =>
        import("../stories/screens/StripeSetupRedirect/StripeSetupRedirect"),
    ),
  },
  {
    path: SCREENS.NOTIFICATIONS,
    component: lazy(
      () =>
        import(
          "../stories/screens/MobileNotificationsScreen/MobileNotificationsScreen"
        ),
    ),
  },
  {
    path: SCREENS.PROJECTS_NO_TAB,
    component: lazy(() => import("../stories/screens/Projects")),
  },
  {
    path: SCREENS.PROJECTS,
    component: lazy(() => import("../stories/screens/Projects")),
    exact: false,
  },
  {
    path: SCREENS.PROJECT,
    component: lazy(
      () => import("../stories/screens/ProjectScreen/ProjectScreen"),
    ),
  },
  {
    path: SCREENS.MESSAGES,
    component: lazy(() => import("../stories/screens/MessagesV2Screen/index")),
  },
  {
    path: SCREENS.COMMUNITY,
    component: lazy(
      () => import("../stories/screens/CommunityScreen/CommunityScreen"),
    ),
    exact: false,
  },
  {
    path: SCREENS.MOBILE_PROJECT_REVIEW,
    component: lazy(
      () =>
        import(
          "../stories/screens/MobileProjectReviewScreen/MobileProjectReviewScreen"
        ),
    ),
  },
  {
    path: SCREENS.FIND_MY_ENGINEER,
    component: lazy(
      () =>
        import("../stories/screens/FindMyEngineerScreen/FindMyEngineerScreen"),
    ),
  },
  {
    path: SCREENS.SEARCH,
    component: lazy(
      () => import("../stories/screens/ExploreScreen/ExploreScreen"),
    ),
    exact: false,
  },
  {
    path: SCREENS.ADD_SUBSCRIPTION,
    component: lazy(
      () =>
        import(
          "../stories/screens/AddSubscriptionScreen/AddSubscriptionScreen"
        ),
    ),
  },
  {
    path: SCREENS.LOAD_PREDEFINED_PROJECT,
    component: lazy(
      () =>
        import(
          "../stories/screens/LoadPredefinedProjectScreen/LoadPredefinedProject"
        ),
    ),
  },
  {
    path: SCREENS.SETTINGS_SCREEN,
    component: lazy(() => import("../stories/screens/HomeScreen/HomeScreen")),
  },
  {
    path: SCREENS.TRANSACTION_OVERVIEW,
    component: lazy(
      () =>
        import(
          "../stories/screens/TransactionOverviewScreen/TransactionOverviewScreen"
        ),
    ),
  },
  {
    path: SCREENS.ONBOARDING_RSVP_SCREEN,
    component: lazy(
      () =>
        import("../stories/screens/OnboardingRSVPScreen/OnboardingRSVPScreen"),
    ),
  },
  {
    path: SCREENS.TEAM_INVITE_SCREEN,
    component: lazy(
      () => import("../stories/screens/TeamInviteScreen/TeamInviteScreen"),
    ),
  },
  {
    path: SCREENS.ADMIN_DASHBOARD,
    component: lazy(
      () =>
        import("../stories/screens/AdminDashboardScreen/AdminDashboardScreen"),
    ),
  },
  {
    path: SCREENS.BLOG_SCREEN,
    component: lazy(() => import("../stories/screens/BlogScreen/BlogScreen")),
  },
  {
    path: SCREENS.TRANSACTION_BOOKING_SCREEN,
    component: lazy(
      () =>
        import(
          "../stories/screens/TransactionBookingScreen/TransactionBookingScreen"
        ),
    ),
  },
  {
    path: SCREENS.LIVESTREAM_SCREEN,
    component: lazy(
      () =>
        import("../stories/screens/LivestreamScreen/LivestreamSelectorScreen"),
    ),
  },
  {
    path: SCREENS.FEATURED_TRACK_REQUEST,
    component: lazy(
      () =>
        import("../stories/screens/FeaturedTrackReview/FeaturedTrackReview"),
    ),
  },
  {
    path: SCREENS.SELECT_FEATURED_TRACK,
    component: lazy(
      () =>
        import(
          "../stories/screens/SelectFeaturedTrackScreen/SelectFeaturedTrackScreen"
        ),
    ),
  },
  {
    path: SCREENS.AUTH_SCREEN,
    component: lazy(() => import("../stories/components/Auth/AuthModal")),
  },
  {
    path: SCREENS.SUBSCRIPTION_SUCCESS,
    component: lazy(
      () =>
        import(
          "../stories/screens/SubscriptionSuccess/SubscriptionSuccessScreen"
        ),
    ),
  },
  {
    path: SCREENS.BEAT_MATRIX,
    component: BeatMatrixScreen,
  },
];

if (ENV === "development") {
  routes.push({
    path: SCREENS.DISTRIBUTION,
    component: lazy(
      () => import("../stories/screens/Distribution/DistributionScreen"),
    ),
  });
}

routes.push(
  // Profile screen needs to go last, as its regex captures all other routes after it
  {
    path: SCREENS.PROFILE_SCREEN,
    component: lazy(
      () => import("../stories/screens/ProfileScreen/ProfileScreenV2"),
    ),
  },
);

export const isAuthenticatedRoute = (pathname: string) => {
  const path = pathname.split("/").filter(Boolean)[0];
  return [
    SCREENS.BOOKINGS,
    SCREENS.ADMIN_DASHBOARD,
    SCREENS.DASHBOARD,
    SCREENS.MESSAGES,
    SCREENS.DASHBOARD_FAVORITES,
    SCREENS.SELECT_FEATURED_TRACK,
    SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN,
    SCREENS.MY_LIBRARY,
  ].some((authenticatedRoute) => authenticatedRoute.includes(path));
};

export default routes;
