export enum AmenityType {
  NO_AMENITY,
  FREE_PARKING,
  FREE_WIFI,
  AIR_CONDITIONING,
  FOOD_AND_DRINKS,
  SECURITY,
  RESTROOM,
  PRIVATE_LOUNGE,
  SMOKING_AREA,
  PET_FRIENDLY,
  RUNNER_AVAILABLE,
  SECURE_ENTRANCE,
  SECURITY_CAMERAS,
  OPEN_KITCHEN,
  STUDIO_MANAGER_ON_PREMISE,
  GEAR_RENTALS,
  WRITING_MATERIALS,
  ENTERTAINMENT,
  VOCAL_BOOTH,
}
import {
  faGamepad,
  faGear,
  faKitchenSet,
  faLock,
  faMicrophone,
  faParagraph,
  faParking,
  faPaw,
  faPersonBooth,
  faRestroom,
  faRunning,
  faShield,
  faSmoking,
  faSnowflake,
  faUserSecret,
  faUtensils,
  faVideoCamera,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";

export interface Amenity {
  id: number;
  created: string;
  deleted: string | null;
  amenity_type: AmenityType;
}

export const amenitiesList = [
  AmenityType.FREE_PARKING,
  AmenityType.FREE_WIFI,
  AmenityType.AIR_CONDITIONING,
  AmenityType.FOOD_AND_DRINKS,
  AmenityType.SECURITY,
  AmenityType.RESTROOM,
  AmenityType.PRIVATE_LOUNGE,
  AmenityType.SMOKING_AREA,
  AmenityType.PET_FRIENDLY,
  AmenityType.RUNNER_AVAILABLE,
  AmenityType.SECURE_ENTRANCE,
  AmenityType.SECURITY_CAMERAS,
  AmenityType.OPEN_KITCHEN,
  AmenityType.STUDIO_MANAGER_ON_PREMISE,
  AmenityType.GEAR_RENTALS,
  AmenityType.WRITING_MATERIALS,
  AmenityType.ENTERTAINMENT,
  AmenityType.VOCAL_BOOTH,
] as AmenityType[];

export const MockAmenities: Amenity[] = [
  {
    id: 1,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.NO_AMENITY,
  } as Amenity,
  {
    id: 2,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.FREE_PARKING,
  } as Amenity,
  {
    id: 3,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.FREE_WIFI,
  } as Amenity,
  {
    id: 4,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.AIR_CONDITIONING,
  } as Amenity,
  {
    id: 5,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.FOOD_AND_DRINKS,
  } as Amenity,
  {
    id: 6,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.SECURITY,
  } as Amenity,
  {
    id: 7,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.RESTROOM,
  } as Amenity,
  {
    id: 8,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.PRIVATE_LOUNGE,
  } as Amenity,
  {
    id: 9,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.SMOKING_AREA,
  } as Amenity,
  {
    id: 10,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.PET_FRIENDLY,
  } as Amenity,
  {
    id: 11,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.RUNNER_AVAILABLE,
  } as Amenity,
  {
    id: 12,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.SECURE_ENTRANCE,
  } as Amenity,
  {
    id: 13,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.SECURITY_CAMERAS,
  } as Amenity,
  {
    id: 14,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.OPEN_KITCHEN,
  } as Amenity,
  {
    id: 15,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.STUDIO_MANAGER_ON_PREMISE,
  } as Amenity,
  {
    id: 16,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.GEAR_RENTALS,
  } as Amenity,
  {
    id: 17,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.WRITING_MATERIALS,
  } as Amenity,
  {
    id: 18,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.ENTERTAINMENT,
  } as Amenity,
  {
    id: 19,
    created: "2020-01-01",
    deleted: null,
    amenity_type: AmenityType.VOCAL_BOOTH,
  } as Amenity,
];

export const amenitiesOptions = [
  {
    value: AmenityType.FREE_PARKING,
    labelText: "Parking",
    labelIcon: faParking,
  },
  { value: AmenityType.FREE_WIFI, labelText: "Free Wifi", labelIcon: faWifi },
  {
    value: AmenityType.AIR_CONDITIONING,
    labelText: "Air Conditioning",
    labelIcon: faSnowflake,
  },
  {
    value: AmenityType.FOOD_AND_DRINKS,
    labelText: "Food and Drinks",
    labelIcon: faUtensils,
  },
  { value: AmenityType.SECURITY, labelText: "Security", labelIcon: faShield },
  { value: AmenityType.RESTROOM, labelText: "Restroom", labelIcon: faRestroom },
  {
    value: AmenityType.PRIVATE_LOUNGE,
    labelText: "Private Lounge",
    labelIcon: faUserSecret,
  },
  {
    value: AmenityType.SMOKING_AREA,
    labelText: "Smoking Area",
    labelIcon: faSmoking,
  },
  {
    value: AmenityType.PET_FRIENDLY,
    labelText: "Pet Friendly",
    labelIcon: faPaw,
  },
  {
    value: AmenityType.RUNNER_AVAILABLE,
    labelText: "Runner Available",
    labelIcon: faRunning,
  },
  {
    value: AmenityType.SECURE_ENTRANCE,
    labelText: "Secure Entrance",
    labelIcon: faLock,
  },
  {
    value: AmenityType.SECURITY_CAMERAS,
    labelText: "Security Cameras",
    labelIcon: faVideoCamera,
  },
  {
    value: AmenityType.OPEN_KITCHEN,
    labelText: "Open Kitchen",
    labelIcon: faKitchenSet,
  },
  {
    value: AmenityType.STUDIO_MANAGER_ON_PREMISE,
    labelText: "Studio Manager",
    labelIcon: faPersonBooth,
  },
  {
    value: AmenityType.GEAR_RENTALS,
    labelText: "Gear Rentals",
    labelIcon: faGear,
  },
  {
    value: AmenityType.WRITING_MATERIALS,
    labelText: "Writing Materials",
    labelIcon: faParagraph,
  },
  {
    value: AmenityType.ENTERTAINMENT,
    labelText: "Video Games",
    labelIcon: faGamepad,
  },
  {
    value: AmenityType.VOCAL_BOOTH,
    labelText: "Vocal Booth",
    labelIcon: faMicrophone,
  },
];
