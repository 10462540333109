import { useAtomValue, useSetAtom } from "jotai";
import { useHistory } from "react-router-dom";
import { AuthFlows, AuthSteps } from "../../constants/authSteps";
import { SCREENS } from "../../routes/screens";
import User from "../../store/models/user";
import {
  getAdminDashboardRoute,
  getDashboardRoute,
} from "../../store/utils/routeGetters";
import { phoneNumberLoginAtom } from "../../stories/components/Auth/atoms";
import { useMediaQueryBreakpoint } from "../useMediaQuery";
import { DISCIPLINE_TYPE, getPrimaryDiscipline } from "../user";
import { isAdmin } from "../useIsAdmin";
import { currentSignUpStepAtom } from "../../atoms/location/currentSignUpStepAtom";
import { authModalOpenAtom } from "../../atoms/location/authModalOpenAtom";
import { currentFlowAtom } from "../../atoms/location/currentFlowAtom";

export const useCheckUserLoginFlow = () => {
  const setFlow = useSetAtom(currentFlowAtom);
  const setStep = useSetAtom(currentSignUpStepAtom);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const { isMobile, isTablet } = useMediaQueryBreakpoint();
  const history = useHistory();
  const isPhoneLogin = useAtomValue(phoneNumberLoginAtom);

  const checkUserAndSetFlow = (user: User, skipFlow?: boolean) => {
    const primaryDiscipline = getPrimaryDiscipline(user);
    const isEmailVerified = user.email && user.email_verified;
    const isPhoneAdded = user.phone_number?.phone_number;
    const isPhoneVerified = user.phone_number?.verified;
    const hasPassword = user.has_password;
    const isAandREmail = user.is_valid_aandr_email;

    // This is useful in the condition where we are redericting from UMG SSO login as it loses the component being mounted
    const shouldOpenModal =
      !skipFlow &&
      (!hasPassword ||
        (isAandREmail && !isEmailVerified) ||
        primaryDiscipline === DISCIPLINE_TYPE.NO_TYPE ||
        !isEmailVerified ||
        !isPhoneAdded ||
        !isPhoneVerified);

    if (skipFlow) {
      setAuthModalOpen(false);
    } else {
      if (!hasPassword) {
        setFlow(AuthFlows.ADD_PASSWORD);
        setStep(AuthSteps.ADD_PASSWORD);
      } else if (isAandREmail && !isEmailVerified) {
        setFlow(AuthFlows.AANDR_NON_UMG);
      } else if (primaryDiscipline === DISCIPLINE_TYPE.NO_TYPE) {
        setFlow(AuthFlows.STANDARD_RESUME_LOGIN);
        if (isPhoneLogin) {
          setFlow(AuthFlows.PHONE_RESUME_LOGIN);
        }
      } else if (!isEmailVerified) {
        if (primaryDiscipline === DISCIPLINE_TYPE.STUDIO_MANAGER) {
          setFlow(AuthFlows.STUDIO_OWNER_RESUME_LOGIN);
        } else {
          setFlow(AuthFlows.STANDARD_RESUME_LOGIN);
          if (isPhoneLogin) {
            setFlow(AuthFlows.PHONE_RESUME_LOGIN);
          }
        }
        setStep(AuthSteps.VERIFY_EMAIL);
      } else if (!isPhoneAdded) {
        if (primaryDiscipline === DISCIPLINE_TYPE.STUDIO_MANAGER) {
          setFlow(AuthFlows.STUDIO_OWNER_RESUME_LOGIN);
          setStep(AuthSteps.ADD_PHONE);
        } else if (
          primaryDiscipline === DISCIPLINE_TYPE.A_AND_R ||
          primaryDiscipline === DISCIPLINE_TYPE.ADMIN
        ) {
          setFlow(AuthFlows.AANDR_RESUME_LOGIN);
          setStep(AuthSteps.AANDR_ADD_PHONE);
        } else {
          setFlow(AuthFlows.STANDARD_RESUME_LOGIN);
          setStep(AuthSteps.ADD_PHONE);
        }
      } else if (!isPhoneVerified) {
        if (primaryDiscipline === DISCIPLINE_TYPE.STUDIO_MANAGER) {
          setFlow(AuthFlows.STUDIO_OWNER_RESUME_LOGIN);
          setStep(AuthSteps.VERIFY_PHONE);
        } else if (
          primaryDiscipline === DISCIPLINE_TYPE.A_AND_R ||
          primaryDiscipline === DISCIPLINE_TYPE.ADMIN
        ) {
          setFlow(AuthFlows.AANDR_RESUME_LOGIN);
          setStep(AuthSteps.AANDR_VERIFY_PHONE);
        } else {
          setFlow(AuthFlows.STANDARD_RESUME_LOGIN);
          setStep(AuthSteps.VERIFY_PHONE);
        }
      } else {
        if (isMobile || isTablet) {
          const dashboardRoute = isAdmin(user)
            ? getAdminDashboardRoute()
            : getDashboardRoute();
          history.push(dashboardRoute);
        }
        setAuthModalOpen(false);
      }

      if (shouldOpenModal) {
        if (isMobile) history.push(SCREENS.AUTH_SCREEN);
        setAuthModalOpen(true);
      }
    }
  };

  return { checkUserAndSetFlow };
};
