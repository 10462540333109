import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import {
  PlaybackControlButton,
  PlaybackControlButtonVariant,
} from "../../elements/PlaybackControlButton/PlaybackControlButton";
import { BeatMatrixMute } from "./BeatMatrixMute";
import { default as MultiTrack, default as Multitrack } from "./multitrack";
import { useBeatMatrixColors } from "./utils";

const hatsUrls = [
  "/audio/hats/ABBP_Trap_Hat_Q_Rock_Loop_02_134BPM_1.m4a",
  "/audio/hats/ABBP_Trap_Hat_SVGAR_Loop_03_160bpm_1.m4a",
  "/audio/hats/ABBP_Trap_Hat_SVGAR_Loop_05_140bpm_1.m4a",
  "/audio/hats/ABBP_Trap_Hat_SVGAR_Loop_07_130bpm_1.m4a",
  "/audio/hats/ABTI3_Q_Rock_Hat_Loop_Trap_In_Eden_140BPM_1.m4a",
  "/audio/hats/bb_drum_loop_broke_hat_90.m4a",
  "/audio/hats/DECAP_89_hihat_loop_dark_swing_bouncy_boom_bap_1.m4a",
  "/audio/hats/nois_catch-me-hats-and-percs_bpm119_Cm_.mp3",
  "/audio/hats/nois_scary hats_bpm89_Cm.mp3",
  "/audio/hats/nois2021_Hats_04.m4a",
  "/audio/hats/nois2021_Hats_10.m4a",
  "/audio/hats/nois2021_Hats_38.m4a",
  "/audio/hats/nois2021_Hats_56.m4a",
  "/audio/hats/OSS_LS1_89_HAT_LOOP_ISLAND_Krs_GROOVE_1.m4a",
  "/audio/hats/PMTH_Cymbal_Loop_89_03.m4a",
]

const kicksUrls = [
  "/audio/kicks/ALF3_Kit_03_808_Sub_Kick_Loop_71.m4a",
  "/audio/kicks/BNYX KICK_2.m4a",
  "/audio/kicks/BNYX KICK.m4a",
  "/audio/kicks/BO_HWD_135_Bass_Loop_808_Patch_Cm_1.m4a",
  "/audio/kicks/DDG_Cm_120_808_loop_1.m4a",
  "/audio/kicks/ELX_NRW_808_loop_07_150_Cm_1.m4a",
  "/audio/kicks/FL_TR_Kit02_Bass_Loop_808_Distorted_100_Cm_1.m4a",
  "/audio/kicks/nois kick_2.m4a",
  "/audio/kicks/nois_catch-me-kick_bpm119_Cm_.mp3",
  "/audio/kicks/NOIS_FUJIWATER_808_2.m4a",
  "/audio/kicks/NOIS_Kick_Jealousy_98.m4a",
  "/audio/kicks/NOIS_Kick_Link Up_2.m4a",
  "/audio/kicks/nois_kick_tidings_61.m4a",
  "/audio/kicks/nois_scary 808_bpm89_Cm.mp3",
  "/audio/kicks/STSH KICKS_1.m4a",
  "/audio/kicks/STSH KICKS_3.m4a",
  "/audio/kicks/STSH KICKS.m4a",
];

const sampleUrls = [
  "/audio/samples/!![Jerk] Strength 97-194bpm Cm [@1ts_katzy]_1.m4a",
  "/audio/samples/![New Jazz] Realest 140bpm Cm [@1ts_katzy]_1.m4a",
  "/audio/samples/''All a Dream-Sample'' (120 Bpm- Cm) Prod.  STAGIO_1.m4a",
  "/audio/samples/''Emeralds-Sample'' (100 Bpm- Cm) Prod.  STAGIO_1.m4a",
  "/audio/samples/''Old Ways -Sample'' (120 Bpm- Cm) Prod.STAGIO_1.m4a",
  "/audio/samples/''Rain On Me -Sample'' (131 Bpm- Cm) Prod.  STAGIO_1.m4a",
  "/audio/samples/''Ski-Sample'' (125 Bpm- Cm) Prod.STAGIO_1.m4a",
  "/audio/samples/(soul, jazz) bdlw 80bpm - Cminor - @do.r3prod @dstbeats__1.m4a",
  "/audio/samples/[Afro] No Sleep 105bpm Cm [@1ts_katzy x @g-sign @prod.malef]_1.m4a",
  "/audio/samples/AAA_Chord_Loop_10_90_Cm_1.m4a",
  "/audio/samples/BOS_ISL_85_Instrument_Qanun_Loop_Sultan_Cm_1.m4a",
  "/audio/samples/BOS_ISL_100_Instrument_Piano_Loop_Retro_Land_Cm_1.m4a",
  "/audio/samples/BOS_ISL_110_Instrument_Piano_Loop_Royal_Cm_1.m4a",
  "/audio/samples/nois_catch-me-sample_bpm119_Cm_.mp3",
  "/audio/samples/nois_scary music all_bpm89_Cm.mp3",
  "/audio/samples/SA Misyats 140 Cmin @ndacompany @ahn (Synth+Virtual+Sad)_1.m4a",
  "/audio/samples/spectra_instruments_guitar_loop_arpPluckRiff_88_Cmin_1.m4a",
  "/audio/samples/TSP_HE_90_melodic_instrumental_found_Cmin_1.m4a",
];

const snaresUrls = [
  "/audio/snares/BNYX CLAPS_2.m4a",
  "/audio/snares/BNYX CLAPS.m4a",
  "/audio/snares/BNYX SNRS_2.m4a",
  "/audio/snares/BNYX SNRS.m4a",
  "/audio/snares/JAHLIL SNARES_1.m4a",
  "/audio/snares/nois rim snr kit_1.m4a",
  "/audio/snares/nois rim snr kit_2.m4a",
  "/audio/snares/nois rim snr kit_3.m4a",
  "/audio/snares/nois rim snr kit_4.m4a",
  "/audio/snares/nois rim snr kit_6.m4a",
  "/audio/snares/nois rim snr kit.m4a",
  "/audio/snares/NOIS_2022_Snares__2.m4a",
  "/audio/snares/NOIS_2022_Snares_.m4a",
];


type AudioFile = {
  id: number;
  startPosition: number;
  loopIndex: number;
  name: string;
  url: string;
  draggable?: boolean;
  options?: Record<string, string>;
};

const BeatMatrixScreen = () => {
  const { palette } = useTheme();
  const beatMatrixColors = useBeatMatrixColors();
  const [audioFiles, setAudioFiles] = useState<AudioFile[]>([
    {
      id: 0,
      startPosition: 0,
      name: "Hi Hats",
      draggable: false,
      loopIndex: 0,
      url: hatsUrls[0],
      options: {
        waveColor: beatMatrixColors(0),
      },
    },
    {
      id: 1,
      startPosition: 0,
      name: "Kick",
      loopIndex: 0,
      url: kicksUrls[0],
      options: {
        waveColor: beatMatrixColors(1),
      },
    },
    {
      id: 2,
      startPosition: 0,
      name: "Sample",
      loopIndex: 0,
      url: sampleUrls[0],
      options: {
        waveColor: beatMatrixColors(2),
      },
    },
    {
      id: 3,
      startPosition: 0,
      name: "Snare",
      loopIndex: 0,
      url: snaresUrls[0],
      options: {
        waveColor: beatMatrixColors(3),
      },
    },
  ]);
  const [localHatsUrls, setLocalHatsUrls] = useState<string[]>(hatsUrls);
  const [localKicksUrls, setLocalKicksUrls] = useState<string[]>(kicksUrls);
  const [localSnaresUrls, setLocalSnaresUrls] = useState<string[]>(snaresUrls);
  const [localSampleUrls, setLocalSampleUrls] = useState<string[]>(sampleUrls);

  useEffect(() => {
    const preloadAudio = async () => {
      const newAudioFiles = await Promise.all(
        audioFiles.map(async (audio) => {
          const response = await fetch(audio.url);
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          return { ...audio, url };
        }),
      );
      setAudioFiles(newAudioFiles);
      setSelectedFiles(newAudioFiles);

      const newKicksUrls = await Promise.all(
        kicksUrls.map(async (url) => {
          const response = await fetch(url);
          const blob = await response.blob();
          const urlObject = URL.createObjectURL(blob);
          return urlObject;
        }),
      );
      setLocalKicksUrls(newKicksUrls);

      const newSnaresUrls = await Promise.all(
        snaresUrls.map(async (url) => {
          const response = await fetch(url);
          const blob = await response.blob();
          const urlObject = URL.createObjectURL(blob);
          return urlObject;
        }),
      );
      setLocalSnaresUrls(newSnaresUrls);

      const newHatsUrls = await Promise.all(
        hatsUrls.map(async (url) => {
          const response = await fetch(url);
          const blob = await response.blob();
          const urlObject = URL.createObjectURL(blob);
          return urlObject;
        }),
      );
      setLocalHatsUrls(newHatsUrls);

      const newSampleUrls = await Promise.all(
        sampleUrls.map(async (url) => {
          const response = await fetch(url);
          const blob = await response.blob();
          const urlObject = URL.createObjectURL(blob);
          return urlObject;
        }),
      );
      setLocalSampleUrls(newSampleUrls);
    };
    preloadAudio().then(() => {
      multiTrackRef.current?.once("canplay", () => {
        try {
          void multiTrackRef.current?.setSinkId("multi-track-player");
        }
        catch (e) {}
      });
    })
  }, []);

  const muteState = useRef([false, false, false, false]);

  const audioRefs = useRef<(HTMLAudioElement | null)[]>(
    audioFiles.map(() => null),
  );
  const [selectedFiles, setSelectedFiles] = useState<AudioFile[]>([
    ...audioFiles,
  ]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [disableControl, setDisableControl] = useState(true);
  const multiTrackRef = useRef<MultiTrack>();
  const handlePauseAll = () => {
    if (!multiTrackRef.current) return;
    setSelectedFiles([...selectedFiles]);
    setIsPlaying(false);
  };

  const handlePlayAll = () => {
    if (!multiTrackRef.current) return;
    if (!multiTrackRef.current.isPlaying()) {
      multiTrackRef.current.play();
    }
    setIsPlaying(true);
  };

  const handleShuffle = (index: number) => {
    setSelectedFiles(
      selectedFiles.map((file, i) => {
        if (i === index) {
          const { loopIndex, ...rest } = file;
          const urls =
            i === 0
              ? localHatsUrls
              : i === 1
              ? localKicksUrls
              : i === 2
              ? localSampleUrls
              : localSnaresUrls;
          const newLoopIndex = (loopIndex || 0) + 1;
          return {
            ...rest,
            loopIndex: newLoopIndex >= urls.length - 1 ? 0 : newLoopIndex,
            url: urls[newLoopIndex],
          };
        }
        return file;
      }),
    );
  };

  // Official doc:  https://wavesurfer-multitrack.pages.dev/docs/types/MultitrackOptions
  useEffect(() => {
    if (!containerRef.current) return;
    if (multiTrackRef.current) {
      multiTrackRef.current.destroy();
    }
    const multiTrack = Multitrack.create(selectedFiles, {
      container: containerRef.current, // required!
      minPxPerSec: 10, // zoom level
      rightButtonDrag: false, // set to true to drag with right mouse button
      cursorWidth: 2,
      cursorColor: "black",
      trackBorderColor: "#7C7C7C",
      dragBounds: true,
      envelopeOptions: {
        lineColor: "rgba(255, 0, 0, 0.7)",
        lineWidth: "4",
        dragPointSize: window.innerWidth < 600 ? 20 : 10,
        dragPointFill: "rgba(255, 255, 255, 0.8)",
        dragPointStroke: "rgba(255, 255, 255, 0.3)",
      },
    });
    if (!multiTrack) return;
    multiTrackRef.current = multiTrack;
    if (isPlaying) {
      setTimeout(() => {
        multiTrack.play();
      }, 150);
      setTimeout(() => {
        muteState.current.forEach((mute, index) => {
          multiTrack.setTrackVolume(index, mute ? 0 : 1);
        });
      }, 150);
    }
  }, [selectedFiles]);

  useEffect(() => {}, [selectedFiles]);

  useEffect(() => {
    return () => {
      multiTrackRef.current?.destroy();
    };
  }, []);

  return (
    <Box
      sx={{ backgroundColor: palette.background.paper }}
      id="beat-matrix-screen"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {/* Title and Controls */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "16px",
            marginTop: "16px",
          }}
        >
          {/* Title */}
          <h3>Beat Matrix</h3>
        </Box>
        {/* Beat Matrix Tracks */}
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "63px",
              marginRight: "16px",
              marginTop: "20px",
            }}
          >
            {selectedFiles.map((file, index) => (
              <Box
                key={file.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* Track Name */}
                <Box
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    color: palette.text.primary,
                  }}
                >
                  {file.name}
                </Box>
                <BeatMatrixMute
                  multiTrackRef={multiTrackRef}
                  muteState={muteState}
                  index={index}
                  beatMatrixColors={beatMatrixColors}
                />
              </Box>
            ))}
          </Box>
          <div
            ref={containerRef}
            id="multi-track-player"
            style={{
              maxWidth: "500px",
              minWidth: "200px",
              position: "relative",
              width: "100%",
              borderRadius: "8px",
              border: "2px sold red",
            }}
          />
          {/* Tracks with Mute/Swap Buttons */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "38px",
              marginLeft: "16px",
              gap: "82px",
            }}
          >
            {selectedFiles.map((file, index) => (
              <Box
                key={file.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {/* Buttons */}
                <Button
                  onClick={() => {
                    if (!multiTrackRef.current) return;
                    handleShuffle(index);
                  }}
                  variant={ButtonVariant.UNSTYLED}
                >
                  <Box
                    alignSelf="stretch"
                    padding={"12px"}
                    sx={() => ({
                      backgroundColor: beatMatrixColors(index),
                      borderRadius: "8px",
                    })}
                  >
                    <FontAwesomeIcon icon={faArrowsRotate} size="xl" />
                  </Box>
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
        {/* Controls */}
        <div className="controls">
          <PlaybackControlButton
            variant={
              isPlaying
                ? PlaybackControlButtonVariant.PAUSE
                : PlaybackControlButtonVariant.PLAY_CIRCLE
            }
            style={{ height: "28px", width: "28px", marginBottom: "32px" }}
            disabled={false}
            isPrimary
            onClick={() => {
              if (multiTrackRef.current?.isPlaying()) {
                handlePauseAll();
              } else {
                handlePlayAll();
              }
            }}
          />
        </div>
      </Box>
    </Box>
  );
};

export default BeatMatrixScreen;
