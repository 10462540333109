import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material";
import { useSetAtom } from "jotai";
import { AuthFlows } from "../../../constants/authSteps";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import useGoogleAuth from "../../../hooks/authHooks/useGoogleAuth";
import GoogleLogoIcon from "../../assets/google-logo-icon.svg";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../core-ui/components/Text/TextUtils";
import {
  CloseButton,
  FormContainer,
  MainContentContainer,
  SignUpButtonsContainer,
  SignUpFooter,
  SignUpSocialButton,
} from "./AuthModal.styles";
import FooterContent from "./FooterContent";
import { currentFlowAtom } from "../../../atoms/location/currentFlowAtom";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { useAppSelector } from "../../../store/hooks";
import { useMediaQueryBreakpoint } from "../../../hooks/useMediaQuery";
import { authModalOpenAtom } from "../../../atoms/location/authModalOpenAtom";

export const StartSignUp = () => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const theme = useTheme();
  const setFlow = useSetAtom(currentFlowAtom);
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const authNavigation = useAuthNavigationSteps();
  const { nextStep } = authNavigation();
  const { handleGoogleClick, isGoogleLoginOpen } = useGoogleAuth();
  const { isDesktop } = useMediaQueryBreakpoint();

  return (
    <FormContainer>
      {isDesktop && (
        <CloseButton>
          <FontAwesomeIcon
            icon={faClose}
            size="lg"
            color={theme.palette.text.primary}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "auth_start_signup_click_on_exit",
                {},
                user?.id,
              );
              setAuthModalOpen(false);
            }}
          />
        </CloseButton>
      )}
      <MainContentContainer>
        <SignUpButtonsContainer>
          <Text variant={TextStyleVariant.H6}>{"Create a new account"}</Text>
          <Text>
            {"Already a member?"}
            <Button
              style={{
                display: "inline",
                marginLeft: "4px",
                color: theme.palette.text.primary,
              }}
              variant={ButtonVariant.TEXT}
              onClick={() => {
                setFlow(AuthFlows.LOGIN);
              }}
            >
              {"Sign in"}
            </Button>
          </Text>
          <SignUpSocialButton
            loading={isGoogleLoginOpen}
            onClick={handleGoogleClick}
            fullWidth={true}
            variant={ButtonVariant.OUTLINED}
          >
            <img
              alt={"google logo icon"}
              src={GoogleLogoIcon}
              width={18}
              height={18}
            />
            Continue with Google
          </SignUpSocialButton>
          <Button
            fullWidth={true}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "auth_start_signup_click_on_continue_with_email",
                {},
                user?.id,
              );
              nextStep();
            }}
          >
            Continue with email
          </Button>
          {/*<Text weight={TEXT_WEIGHT.SEMI_BOLD} color={TextColor.TERTIARY}>
          OR
        </Text>
        <SignUpSocialButtonsContainer>
          <SignUpSocialButton fullWidth={true} variant={ButtonVariant.OUTLINED}>
            <AppleLogo icon={faApple} />
            Apple
          </SignUpSocialButton>
          <SignUpSocialButton fullWidth={true} variant={ButtonVariant.OUTLINED}>
            <FacebookLogo icon={faFacebookF} />
            Facebook
          </SignUpSocialButton>
        </SignUpSocialButtonsContainer>*/}
        </SignUpButtonsContainer>
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
