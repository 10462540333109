import { createRoot } from "react-dom/client";
// @ts-ignore
import HttpsRedirect from "react-https-redirect";
import App from "./App";
import { Provider } from "react-redux";
import {
  createStore as createJotaiStore,
  Provider as JotaiProvider,
} from "jotai";
import store from "./store";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/umgAuthConfig";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { ENV, INSTANCE_URL, VERSION } from "./constants/generated";
import * as Geocode from "react-geocode";
import { GOOGLE_API } from "./store/utils";
import { consentAtom } from "./atoms/consentAtom";
import { registerSW } from "virtual:pwa-register";

registerSW({ immediate: true });
// Used for UMG SSO authentication.
const msalInstance = new PublicClientApplication(msalConfig);
const datadogVersion = `${VERSION}-${INSTANCE_URL ? "firebase" : "django"}`;
const jotaiStore = createJotaiStore();
const hasConsent = jotaiStore.get(consentAtom);

Geocode.setKey(GOOGLE_API);
Geocode.setLanguage("en");
Geocode.setRegion("us");

datadogLogs.init({
  clientToken: "pub98e8687c13143116e0595d0a98473cb4",
  site: "us5.datadoghq.com",
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: "engineears",
  trackingConsent: `${!hasConsent ? "not-" : ""}granted`,
  env: ENV,
  version: datadogVersion,
});

datadogRum.init({
  applicationId: "84b62862-2443-4ce0-b664-457a5f78247c",
  clientToken: "pub98e8687c13143116e0595d0a98473cb4",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "us5.datadoghq.com",
  service: "engineears",
  trackingConsent: `${!hasConsent ? "not-" : ""}granted`,
  env: ENV,
  allowedTracingUrls: [
    "http://localhost:8080/api/",
    /https:\/\/.*\.engineears\.com\/api\//,
    /https:\/\/.*\..*-?engineears-215922\.appspot\.com\/api\//,
  ],
  // Specify a version number to identify the deployed version of your application in Datadog
  version: datadogVersion,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  enableExperimentalFeatures: ["feature_flags"],
  defaultPrivacyLevel: "mask-user-input",
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <HttpsRedirect>
    <JotaiProvider store={jotaiStore}>
      <Provider store={store}>
        <BrowserRouter>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </BrowserRouter>
      </Provider>
    </JotaiProvider>
  </HttpsRedirect>,
);
