import { Box, useTheme } from "@mui/material";
import React, { MutableRefObject, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDrum, faPiano } from "@fortawesome/pro-regular-svg-icons";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import MultiTrack from "./multitrack";

interface BeatMatrixMuteProps {
  multiTrackRef: MutableRefObject<MultiTrack | undefined>;
  muteState: MutableRefObject<boolean[]>;
  index: number;
  beatMatrixColors: (index: number, isDisabled?: boolean) => string;
}

export const BeatMatrixMute = ({
  multiTrackRef,
  muteState,
  index,
  beatMatrixColors,
}: BeatMatrixMuteProps) => {
  const [isMute, setIsMute] = useState(false);
  const { palette } = useTheme();
  return (
    <Button
      onClick={() => {
        if (!multiTrackRef.current) return;
        if (muteState.current[index] === false) {
          console.log("Muting track", multiTrackRef.current);
          multiTrackRef.current.setTrackVolume(index, 0);
          muteState.current[index] = true;
          setIsMute(true);
        } else {
          console.log("Unmuting track", multiTrackRef.current);
          multiTrackRef.current.setTrackVolume(index, 1);
          muteState.current[index] = false;
          setIsMute(false);
        }
      }}
      variant={ButtonVariant.UNSTYLED}
    >
      <Box
        padding={"12px"}
        sx={() => ({
          backgroundColor: beatMatrixColors(index, isMute),
          borderRadius: "8px",
        })}
      >
        <FontAwesomeIcon
          color={isMute ? palette.text.disabled : palette.text.primary}
          icon={index === 2 ? faPiano : faDrum}
          size="xl"
        />
      </Box>
    </Button>
  );
};
