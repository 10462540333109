import { useTheme } from "@mui/material";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import { AuthFlows, AuthSteps } from "../../../constants/authSteps";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { useCheckUserLoginFlow } from "../../../hooks/authHooks/useCheckUserLoginFlow";
import useGetGuestVerificationCodeMutation from "../../../hooks/authHooks/useGetGuestVerificationCodeMutation";
import useGuestLoginMutation from "../../../hooks/authHooks/useGuestLoginMutation";
import {
  AuthResponse,
  updateProfile,
  verifyAccountPhoneNumber,
} from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { phoneNumberAtom, phoneNumberRegisterAtom } from "./atoms";
import { currentFlowAtom } from "../../../atoms/location/currentFlowAtom";
import {
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import FooterContent from "./FooterContent";
import { VerifyPhoneInput } from "./VerifyPhoneInput";
import { currentSignUpStepAtom } from "../../../atoms/location/currentSignUpStepAtom";

export const VerifyPhone = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.accountInfo);
  const authNavigation = useAuthNavigationSteps();
  const { nextStep, prevStep } = authNavigation();
  const { mutateAsync: guestLoginAsync } = useGuestLoginMutation();
  const { mutateAsync: getVerificationCodeAsync } =
    useGetGuestVerificationCodeMutation();
  const phoneNumber = useAtomValue(phoneNumberAtom);
  const [flow, setFlow] = useAtom(currentFlowAtom);
  const isPhoneLogin = flow === AuthFlows.PHONE_LOGIN;
  const phoneNumberRegister = useAtomValue(phoneNumberRegisterAtom);
  const { checkUserAndSetFlow } = useCheckUserLoginFlow();
  const setAuthStep = useSetAtom(currentSignUpStepAtom);

  const numberToVerify = useMemo(
    () => phoneNumber || user?.phone_number,
    [phoneNumber, user?.phone_number],
  );

  const handleVerifyPhone = async (
    verificationCode: string,
  ): Promise<boolean> => {
    if (!user?.id) return true;
    return dispatch(
      verifyAccountPhoneNumber({
        verification: verificationCode,
      }),
    )
      .unwrap()
      .then(() => {
        emitAnalyticsTrackingEvent("auth_verify_phone_used_code", {}, user?.id);
        nextStep();
        return true;
      })
      .catch(() => {
        emitAnalyticsTrackingEvent(
          "auth_verify_phone_used_code_error",
          {},
          user?.id,
        );
        return false;
      });
  };

  const handlePhoneLogin = async (verificationCode: string) => {
    return guestLoginAsync({
      phone_number: phoneNumber,
      verification_code: verificationCode,
    })
      .then((data) => {
        if (phoneNumberRegister) {
          setFlow(AuthFlows.PHONE_SIGNUP);
          return true;
        } else if (isPhoneLogin) {
          checkUserAndSetFlow((data as AuthResponse).user);
          return true;
        }
        nextStep();
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  const handleSubmit = () => {
    emitAnalyticsTrackingEvent(
      "auth_verify_phone_clicked_submit",
      {},
      user?.id,
    );
    if (phoneNumberRegister) {
      setFlow(AuthFlows.PHONE_SIGNUP);
      return;
    } else if (isPhoneLogin && user) {
      checkUserAndSetFlow(user);
      return;
    }
    nextStep();
  };

  const handleResendVerifyPhone = async () => {
    if (isPhoneLogin) {
      await getVerificationCodeAsync({ phone_number: phoneNumber });
      return;
    }
    await dispatch(
      updateProfile({ phone_number: user?.phone_number?.phone_number }),
    ).then(() => {
      emitAnalyticsTrackingEvent(
        "auth_verify_phone_clicked_resend",
        {},
        user?.id,
      );
    });
  };

  const handleDifferentPhoneNumber = () => {
    emitAnalyticsTrackingEvent(
      "auth_verify_phone_clicked_use_different_phone",
      {},
      user?.id,
    );
    if (isPhoneLogin) {
      setFlow(AuthFlows.PHONE_ADD);
      return;
    }
    prevStep();
  };

  if (!numberToVerify) {
    setAuthStep(AuthSteps.START_LOGIN);
    return null;
  }

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <VerifyPhoneInput
          phoneNumber={user?.phone_number?.phone_number}
          handleVerifyPhone={
            isPhoneLogin ? handlePhoneLogin : handleVerifyPhone
          }
          handleResendVerifyPhone={handleResendVerifyPhone}
          handleSubmit={handleSubmit}
          handleDifferentPhoneNumber={handleDifferentPhoneNumber}
        />
        {!isPhoneLogin && (
          <Button
            style={{
              alignSelf: "flex-end",
              fontWeight: "600",
              color: theme.palette.text.primary,
            }}
            variant={ButtonVariant.TEXT}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "auth_verify_phone_clicked_remind_me_later",
                {},
                user?.id,
              );
              nextStep({ skipVerification: true });
            }}
          >
            Remind me later
          </Button>
        )}
      </MainContentContainer>
      <SignUpFooter>
        <FooterContent />
      </SignUpFooter>
    </FormContainer>
  );
};
