import { Box, css, styled, TextField } from "@mui/material";

export const StyledAccountSettingsFormModalBody = css`
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
  max-height: 60vh;
`;

export const AccountDetailsFieldContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  width: "100%",
}));

export const AccountDetailsBody = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "48px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const AccountDetailsColumn = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  flex: 1,
}));

export const FieldInput = styled(TextField)`
  border-radius: 8px;

  & .MuiOutlinedInput-root {
    & fieldset {
      border-radius: 8px;
    }
  }
`;

export const SeperatorLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: theme.palette.divider,
  marginTop: "24px",
}));

export const PaymentAndBillingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  gap: "48px",
  width: "100%",
  paddingBottom: "24px",
  "& > *": {
    flex: 1,
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));
