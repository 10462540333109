import { Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { getDocUrl } from "../../../constants/googleStorage";
import { useAuthNavigationSteps } from "../../../hooks/authHooks/useAuthNavigationSteps";
import { updateProfile } from "../../../store/actions/accountInfo";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import { Text } from "../../core-ui/components/Text/Text";
import { TextColor } from "../../core-ui/components/Text/TextUtils";
import {
  CloseButton,
  FormContainer,
  MainContentContainer,
  SignUpFooter,
} from "./AuthModal.styles";
import { AuthNavigation } from "./AuthNavigation";
import { emitAnalyticsTrackingEvent } from "../../../utils/analyticsUtils";
import { AddPhoneInput } from "./AddPhoneInput";
import useGetGuestVerificationCodeMutation from "../../../hooks/authHooks/useGetGuestVerificationCodeMutation";
import { useAtom, useSetAtom } from "jotai";
import {
  phoneNumberAtom,
  phoneNumberLoginAtom,
  phoneNumberRegisterAtom,
} from "./atoms";
import { AuthFlows } from "../../../constants/authSteps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { authModalOpenAtom } from "../../../atoms/location/authModalOpenAtom";
import { currentFlowAtom } from "../../../atoms/location/currentFlowAtom";

export const AddPhone = () => {
  const user = useAppSelector((state) => state.accountInfo.user);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const authNavigation = useAuthNavigationSteps();
  const { nextStep } = authNavigation();
  const [flow, setFlow] = useAtom(currentFlowAtom);
  const setPhoneNumber = useSetAtom(phoneNumberAtom);
  const setPhoneNumberRegister = useSetAtom(phoneNumberRegisterAtom);
  const setPhoneNumberLogin = useSetAtom(phoneNumberLoginAtom);
  const { mutateAsync: getVerificationCodeAsync } =
    useGetGuestVerificationCodeMutation();
  const isPhoneLogin = flow === AuthFlows.PHONE_ADD;
  const setAuthModalOpen = useSetAtom(authModalOpenAtom);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddPhoneNumber = (phoneNumber: string) => {
    emitAnalyticsTrackingEvent("auth_add_phone_clicked_submit", {}, user?.id);
    setIsLoading(true);
    dispatch(updateProfile({ phone_number: phoneNumber }))
      .unwrap()
      .then(() => {
        nextStep();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePhoneLogin = (phoneNumber: string) => {
    setIsLoading(true);
    getVerificationCodeAsync({ phone_number: phoneNumber })
      .then((data) => {
        setPhoneNumber(phoneNumber);
        if (data.user_id && data.created_account) {
          setPhoneNumberRegister(true);
        } else {
          setPhoneNumberLogin(true);
        }
        setFlow(AuthFlows.PHONE_LOGIN);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const signUpFooter = (
    <Box>
      {isPhoneLogin && (
        <CloseButton>
          <FontAwesomeIcon
            icon={faClose}
            size="lg"
            color={theme.palette.text.primary}
            onClick={() => {
              setAuthModalOpen(false);
            }}
          />
        </CloseButton>
      )}
      <Text color={TextColor.SECONDARY}>
        {"By signing up, you are agreeing to the "}
        <Link
          style={{
            color: theme.palette.text.secondary,
            textDecoration: "underline",
          }}
          to={getDocUrl("EngineEarsTermsOfUse.pdf")}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </Link>
        {" and "}
        <Link
          style={{
            color: theme.palette.text.secondary,
            textDecoration: "underline",
          }}
          to={getDocUrl("EngineEarsPrivacyPolicy.pdf")}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
        .
      </Text>
    </Box>
  );

  return (
    <FormContainer>
      <AuthNavigation />
      <MainContentContainer>
        <AddPhoneInput
          guestAuth={false}
          phoneLogin={isPhoneLogin}
          isLoading={isLoading}
          handleAddPhoneNumber={
            isPhoneLogin ? handlePhoneLogin : handleAddPhoneNumber
          }
        />
        {!isPhoneLogin && (
          <Button
            variant={ButtonVariant.UNSTYLED}
            style={{
              textDecoration: "underline",
              alignSelf: "flex-end",
              color: theme.palette.text.primary,
            }}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "auth_add_phone_clicked_remind_me_later",
                {},
                user?.id,
              );
              nextStep({ skipVerification: true });
            }}
          >
            Remind me later
          </Button>
        )}
      </MainContentContainer>
      <SignUpFooter>{signUpFooter}</SignUpFooter>
    </FormContainer>
  );
};
