export enum SCREENS {
  MY_LIBRARY = "/my-library",
  RELEASE_CHECKOUT = "/checkout/releases/:transaction_code",
  RELEASE_DETAIL = "/release/:release_id",
  PAGINATED_STUDIOS = "/paginated-studios",
  PAGINATED_STUDIOS_ALT = "/for-studios",
  PAGINATED_ENGINEERS = "/paginated-engineers",
  THE_SOUND_OF_TOMORROW = "/thesoundoftomorrow",
  BLOG_SCREEN = "/blog",
  SOUND_OF_TOMORROW = "/soundoftomorrow",
  SEEING_SOUNDS = "/seeingsounds",
  DREAMVILLE = "/dreamville",
  NAMM = "/namm",
  MIXHER = "/mixher",
  SIGN_IN_SCREEN = "/signin",
  SIGN_UP_SCREEN = "/signup",
  HOME_SCREEN = "/",
  SIGNED_OUT_HOME_SCREEN = "/for-all",
  LEGACY_FOR_ARTISTS = "/for-artists",
  LEGACY_FOR_ENGINEERS = "/for-engineers",
  PROFILE_SCREEN = "/:username",
  MESSAGES = "/messages",
  COMMUNITY = "/community",
  DASHBOARD_FAVORITES = "/dashboard/favorites",
  DASHBOARD = "/dashboard",
  NOTIFICATIONS = "/notifications",
  MOBILE_CHECKOUT = "/checkout",
  STUDIO_SCREEN = "/studio/:username",
  STUDIO_ROOM = "/studio/:username/:room_id",
  MOBILE_PROJECT_REVIEW = "/project-review",
  PROJECTS_NO_TAB = "/projects",
  PROJECTS = "/projects/:tab",
  SEARCH = "/search",
  FIND_MY_ENGINEER = "/findmyengineer",
  PROJECT = "/project/:project_id",
  KLARNA_PAYMENT_REDIRECT = "/klarnapayment",
  AFFIRM_PAYMENT_REDIRECT = "/affirmpayment",
  VERIFY_EMAIL = "/verify-email",
  UPLOAD_SCREEN = "/upload/:project_id",
  LOGGED_OUT_REVIEW = "/listen/:project_id",
  LOGGED_OUT_SESSION_DETAILS = "/session-details/:project_id",
  SETUP_STRIPE = "/stripe-setup",
  PASSWORD_RESET = "/reset-password",
  LOG_OUT_ACTION = "/log-out-action",
  ADD_SUBSCRIPTION = "/add-subscription",
  UPDATE_ACCOUNT_DETAILS = "update-account-details",
  UPDATE_CONTACT_INFO = "update-contact-info",
  LOAD_PREDEFINED_PROJECT = "/purchase-project",
  TRANSACTION_OVERVIEW = "/transaction-overview/:transaction_id",
  RECORDING_BOOKING_CHECKOUT = "/recording-session/checkout",
  RECORDING_BOOKING_SCREEN = "/recording-booking",
  CUSTOMER_PORTAL = "/customer-portal/:tab",
  SCHEDULED_PROJECT_OVERVIEW_SCREEN = "/project-overview/:scheduled_project_id",
  FEATURED_TRACK_REQUEST = "/featured-track/:featured_track_id",
  AANDR_SETUP_SCREEN = "/for-aandr",
  DOLBY_REGISTRATION_CHECKPOINT = "/dolby-registration",
  NAMM_REGISTRATION_CHECKPOINT = "/namm-registration",
  USER_FEEDBACK_RATING = "/user-feedback",
  RECORDING_SESSION = "/session/:project_id",
  CASH_APP_REDIRECT = "/cashapppayment",
  SETTINGS_SCREEN = "/settings",
  ONBOARDING_RSVP_SCREEN = "/onboardingrsvp",
  TEAM_INVITE_SCREEN = "/invites",
  ADMIN_DASHBOARD = "/admin-dashboard",
  TRANSACTION_BOOKING_SCREEN = "/booking/:transaction_code",
  BOOKINGS = "/bookings/:tab",
  MUSO_AUTH_REDIRECT_SCREEN = "/musoauth",
  MUSO_SELECTION_SCREEN = "/muso/:tab",
  LIVESTREAM_SCREEN = "/livestream",
  AUTH_SCREEN = "/auth",
  SELECT_FEATURED_TRACK = "/select-featured-track",
  SUBSCRIPTION_SUCCESS = "/subscription-success",
  DISTRIBUTION = "/distribution",
  BEAT_MATRIX = "/beatmatrix",
}
